import React, { useContext, useState, useEffect } from 'react'
import SbEditable from 'storyblok-react'
import Layout from '../layout'
import { ThemeProvider } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import { Box, CssBaseline } from '@material-ui/core'
import ACC_THEME from '../../themes'
import PageContext from '@context'
import isInBrowser from '@helpers/is-in-browser'

const PodcastIndex = (props) => {
  const { body, metaData } = props.blok
  const [podcastContent, setPodcastContent] = useState([...body])
  const [shouldAutoLoadVideo, setShouldAutoLoadVideo] = useState(false)
  const allPodcastData = [...body]

  const pageContext = useContext(PageContext)
  const podcastLogosModule = !!pageContext && pageContext.podcastLogosModule
  const podcastFormCtaModule = !!pageContext && pageContext.podcastFormCtaModule

  useEffect(() => {
    if (isInBrowser) {
      const params = new URLSearchParams(
        !!pageContext && pageContext.location
          ? pageContext.location.search
          : window.location.search
      )
      const shouldAutoLoad = params.get('watch') === 'true'

      setShouldAutoLoadVideo(shouldAutoLoad)
    }
  }, [pageContext])

  let doesPodcastLogoModuleExist =
    podcastLogosModule && podcastLogosModule.hasOwnProperty('component')

  let doesPodcastFormCtaModuleExist =
    podcastFormCtaModule && podcastFormCtaModule.hasOwnProperty('component')

  useEffect(() => {
    if (!!doesPodcastLogoModuleExist && !!doesPodcastFormCtaModuleExist) {
      allPodcastData.splice(1, 0, podcastLogosModule, podcastFormCtaModule)
      setPodcastContent(allPodcastData)
    }
  }, [doesPodcastLogoModuleExist, doesPodcastFormCtaModuleExist])

  return (
    <Layout metaData={metaData}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        <SbEditable content={props.blok}>
          {!!podcastContent && podcastContent.length > 0 && (
            <Box>
              {renderBloks(podcastContent, {
                shouldAutoLoadVideo,
                isPodcastModule: true,
              })}
            </Box>
          )}
        </SbEditable>
      </ThemeProvider>
    </Layout>
  )
}

export default PodcastIndex
